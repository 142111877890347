<template>
  <div class="error">
    <img src="../../assets/image/error.png" width="210px" height="149px" />
    <div style="color: #4a4a4a; font-size: 15px; margin: 24px 0 18px 0">
      此版本不支援賺點任務，
      <br />
      請更新至App最新版本後加入。
    </div>
    <div @click="linkToUpdate" style="color: #30a3d7">前往更新</div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Error',
  computed: {
    ...mapGetters(['system']),
  },
  methods: {
    ...mapActions(['sentApp']),
    linkToUpdate() {
      let storeUrl = '';
      switch (this.system) {
        case 'Android':
          storeUrl =
            'https://play.google.com/store/apps/details?id=com.cloudmobile.einvoice&hl=zh_TW&gl=US';
          break;
        case 'iOS':
          storeUrl =
            'https://apps.apple.com/tw/app/%E9%9B%B2%E7%AB%AF%E7%99%BC%E7%A5%A8/id512920023';
          break;
      }

      this.sentApp({
        action: 'openBrowser',
        payload: { url: storeUrl, go_outside_app: 1 },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
